<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Promocje</b>
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview title="Lista promocji" v-if="state === 'loaded'">
          <template v-slot:toolbar-action>
            <div>
              <b-button
                variant="primary"
                size="sm"
                class="mb-1"
                @click="showModal()"
              >
                dodaj<b-icon icon="plus" aria-hidden="true"></b-icon>
              </b-button>
            </div>
          </template>
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                :items="promotions.items"
                :fields="promotions.fields"
                :filter="filter"
                :tbody-tr-class="rowClass"
              >
                <template #cell(public)="row">
                  <b-icon
                    v-if="row.item.public"
                    icon="eye"
                    aria-hidden="true"
                  ></b-icon>
                  <b-icon v-else icon="eye-slash" aria-hidden="true"></b-icon>
                </template>
                <template #cell(actions)="row">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-1"
                    @click="showPromotion(row.item)"
                  >
                    <b-icon icon="pencil" aria-hidden="true"></b-icon>
                  </b-button>
                  &nbsp;
                  <b-button
                    v-if="row.item.status == 'BUFOR'"
                    variant="danger"
                    size="sm"
                    class="mb-1"
                    @click="deletePromotion(row.item.id)"
                  >
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
        </KTCodePreview>
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </div>
    <AddPromotionModal
      :id="id"
      :title="'Nowa promocja zostanie dodana'"
      @refreshData="onSave"
    >
    </AddPromotionModal>
  </div>
</template>
<style>
.action-column {
  width: 50px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import KTCodePreview from "@/view/content/CodePreview";
import AddPromotionModal from "@/view/pages/admin/promotions/AddPromotionModal.vue";
import Swal from "sweetalert2";

export default {
  name: "ClaimsAdmin",
  data() {
    return {
      id: "addFile",
      state: "loading",
      promotions: {
        fields: [
          {
            key: "name",
            label: "Nazwa",
            sortable: true,
          },
          {
            key: "startDate",
            label: "Data Startu",
            sortable: true,
          },
          {
            key: "endDate",
            label: "Data Końca",
            sortable: true,
          },
          {
            key: "value",
            label: "Promocja",
            sortable: true,
          },
          {
            key: "public",
            label: "Widoczność",
            sortable: true,
            class: "text-center",
          },
          {
            key: "status",
            label: "Status",
            sortable: true,
          },
          {
            key: "actions",
            label: "Akcje",
            thClass: "action-column",
          },
        ],
        items: [],
      },
      filter: null,
      formValidated: false,
    };
  },
  components: {
    KTCodePreview,
    AddPromotionModal,
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "WPROWADZONA") return "table-danger";
      if (item.status === "POTWIERDZONA") return "table-success";
    },
    getPromotions() {
      ApiService.get("/promotions").then((response) => {
        this.promotions.items = response.data;
        this.state = "loaded";
      });
    },
    showPromotion(row) {
      if (row.status === "POTWIERDZONA") {
        Swal.fire({
          title: "Jesteś pewny?",
          text:
            "Edytujesz potwierdzoną promocję. Pamiętaj, że każda zmiana będzie miała konsekwencje w naliczaniu tej promocji!",
          showCancelButton: true,
          confirmButtonColor: "#009136",
          confirmButtonText: "Rozumiem",
          cancelButtonText: "Nie",
          reverseButtons: true,
          icon: "info",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "promotion", params: { id: row.id } });
          }
        });
      } else {
        this.$router.push({ name: "promotion", params: { id: row.id } });
      }
    },
    deletePromotion(id) {
      Swal.fire({
        title: "Jesteś pewny??",
        icon: "question",
        text: "Operacja nieodwracalna",
        showCancelButton: true,
        confirmButtonColor: "#009136",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak, usuń",
        cancelButtonText: "Nie",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          ApiService.delete(`/promotion/delete/${id}`).then(() => {
            this.getPromotions();
          });
        }
      });
    },
    showModal() {
      this.$bvModal.show(this.id);
    },
    onSave(promotions) {
      console.log(promotions);
      this.promotions.items = promotions;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Administrator" },
      { title: "Promocje" },
      { title: "" },
    ]);
    this.getPromotions();
  },
};
</script>
