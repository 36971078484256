<template>
  <div>
    <!-- details modal  -->
    <b-modal
      size="xl"
      scrollable
      :id="id"
      :title="title"
      @ok="handleOk"
      @shown="onShown"
      @show="resetModal"
    >
      <div class="d-block text-center"></div>

      <form class="form" novalidate="novalidate" id="add_promotion_form">
        <b-row>
          <b-col cols="8">
            <b-form-group
              id="fieldsetName"
              label="Nazwa Promocji"
              label-for="name"
            >
              <b-form-input
                v-model="promotion.name"
                name="name"
                ref="name"
                placeholder="nazwa"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="dateFrom"
              label="Start promocji"
              label-for="startDate"
            >
              <b-form-input
                v-model="promotion.startDate"
                id="start-date"
                name="startDate"
                ref="startDate"
                type="date"
                placeholder=""
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <b-form-group
              id="fieldsetDescription"
              label="Opis"
              label-for="description"
            >
              <b-form-textarea
                id="textarea"
                v-model="promotion.description"
                name="description"
                ref="description"
                placeholder="dodatkowy opis promocji widoczny na dashboard uczestnika"
                rows="10"
                max-rows="10"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="dateTo"
              label="Koniec promocji"
              label-for="endDate"
            >
              <b-form-input
                v-model="promotion.endDate"
                name="endDate"
                ref="endDate"
                type="date"
                placeholder=""
              ></b-form-input>
            </b-form-group>
            <b-form-group id="newConverter" label="Konwerter" label-for="value">
              <b-form-input
                v-model="promotion.value"
                size="lg"
                name="value"
                ref="value"
                type="number"
                placeholder="promocyjny konwerter"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Widoczność" label-for="value">
              <b-form-checkbox
                size="lg"
                v-model="promotion.isPublic"
                name="promotion-visibility"
                switch
              >
                pokaz na pulpicie uczestnika
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="4"
            ><b-form-group label="SMS" label-for="value">
              <b-form-checkbox
                size="lg"
                v-model="promotion.autoSms"
                name="auto-sms"
                switch
              >
                automatyczna informacja SMS
              </b-form-checkbox>
            </b-form-group></b-col
          >
          <b-col cols="4">
            <b-form-group
              label="Powiadomienie dni przed startem promocji"
              label-for="value"
            >
              <b-form-input
                v-model="promotion.smsDaysBefore"
                name="smsDaysBefore"
                ref="smsDaysBefore"
                type="number"
                placeholder="promocyjny konwerter"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <!-- end of details modal -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import StartEndDate from "@/assets/plugins/formvalidation/dist/es6/plugins/StartEndDate";

export default {
  name: "AddPromotionModal",
  props: {
    id: String,
    title: String,
  },
  data() {
    return {
      promotion: {
        name: "",
        startDate: "",
        endDate: "",
        description: "",
        status: "BUFOR",
        value: "",
        isPublic: false,
        autoSms: false,
        smsDaysBefore: 7,
      },
    };
  },
  mounted() {},
  methods: {
    onShown() {
      const promotion_form = KTUtil.getById("add_promotion_form");

      this.promotionForm = formValidation(promotion_form, {
        fields: {
          name: {
            validators: {
              notEmpty: {
                message: "Proszę wpisać nazwę",
              },
            },
          },
          startDate: {
            validators: {
              notEmpty: {
                message: "Proszę wybrać datę startu promocji",
              },
              date: {
                min: "YYYY/MM/DD",
                message: "The value is not a valid date",
              },
            },
          },
          endDate: {
            validators: {
              notEmpty: {
                message: "Proszę wybrać datę końca promocji",
              },
            },
          },
          description: {
            validators: {
              notEmpty: {
                message:
                  "Opisz promocję, opis może być widoczny na pulpicie uczestnika",
              },
            },
          },
          smsDaysBefore: {
            validators: {
              notEmpty: {
                message:
                  "Ustaw wartość dni nawet jeśli nie zaznaczono auto SMS",
              },
              numeric: {
                message: "Pole powinno być liczbą",
              },
              greaterThan: {
                inclusive: true,
                min: 1,
                message: "Wyślij SMS min 1 dzień wcześniej",
              },
            },
          },
          value: {
            validators: {
              notEmpty: {
                message: "Promocyjny konwerter nie może być pusty",
              },
              numeric: {
                message: "Konwerter to liczba!",
              },
              greaterThan: {
                inclusive: false,
                min: 0,
                message: "Konwerter musi być większy od zera",
              },
            },
          },
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap(),
          startEndDate: new StartEndDate({
            format: "YYYY-MM-DD",
            startDate: {
              field: "startDate",
              message:
                "Data startu musi być wcześniejsza od daty końca promocji",
            },
            endDate: {
              field: "endDate",
              message:
                "Data końca musi być późniejsza od daty począ†ku promocji",
            },
          }),
        },
      });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.promotionForm.validate().then((v) => {
        if (v === "Valid") {
          ApiService.post("/promotion/add", this.promotion).then(() => {
            this.refreshPromotions();
            this.$nextTick(() => {
              this.$bvModal.hide(this.$props.id);
            });
          });
        }
      });
    },

    refreshPromotions() {
      ApiService.get("/promotions").then((response) => {
        const promotions = response.data;
        this.$emit("refreshData", promotions);
      });
    },

    resetModal() {
      this.promotion.startDate = new Date().toISOString().slice(0, 10);
      this.promotion.name = "";
      this.promotion.endDate = "";
      this.promotion.description = "";
      this.promotion.value = "";
      this.promotion.isPublic = false;
      this.promotion.autoSms = false;
      this.promotion.smsDaysBefore = 7;
    },
  },
};
</script>
